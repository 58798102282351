<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <!--  -->
      <v-container>
        <v-row>
          <v-col md="2" class="mr-2" v-if="returnAbility('grade-type:store')">
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="green"
                  class="white--text"
                >
                  <v-icon size="20">mdi-plus</v-icon>
                  {{ $t("add class type") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  <h2>{{ $t("add class type") }}</h2>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    :label="$t('name')"
                    v-model="newGradeType.name"
                    outlined
                    dense
                    color="#757575"
                    type="text"
                  ></v-text-field>
                  <div class="d-flex justify-center align-center">
                    <v-text-field
                      :label="$t('serial number')"
                      v-model="newGradeType.serial"
                      outlined
                      dense
                      color="#757575"
                      type="number"
                    ></v-text-field>
                    <p>-412</p>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    :disabled="disableSubmitBtn"
                    color="green"
                    class="white--text"
                    @click="submitNewGradeType"
                    :loading="submitBtnLoading"
                    >{{ $t("storage") }}</v-btn
                  >
                  <!-- :disabled="disableSubmitBtn" -->
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col md="3" class="d-flex justify-center align-center">
            <v-text-field
              v-model="nameSearch"
              :label="$t('search by name')"
              dense
              outlined
              hide-details
            ></v-text-field>
            <v-icon class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <!--  -->
      <app-base-table
        :tableOptions="tableOptions"
        @re-fetch-data="fetchData($event)"
        @re-fetch-paginated-data="fetchData($event)"
        serverRoute="/grade-type"
        :enableDelete="displayDeleteIcon"
      >
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon
            v-if="returnAbility('subject:index')"
            color="green"
            @click="
              $router.push({
                path: '/subjects',
                query: { grade_type_id: row.id },
              })
            "
            >mdi-bookshelf</v-icon
          >
          <app-edit-grade-type-modal
            v-if="returnAbility('grade-type:update')"
            @recordUpdated="fetchData"
            :recordData="row"
          ></app-edit-grade-type-modal>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import editGradeTypeModal from "./editGradeTypeModal.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    appEditGradeTypeModal: editGradeTypeModal,
  },
  data() {
    return {
      nameSearch: "",
      dialog: false,
      submitBtnLoading: false,
      loading: false,
      newGradeType: {
        name: "",
        serial: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "gradeType/getTableOptions",
    }),
    disableSubmitBtn() {
      if (!this.newGradeType.name) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchGradeTypes: "gradeType/fetchGradeTypes",
    }),
    async fetchData(paginationValue) {
      this.fetchGradeTypes({
        page: paginationValue,
        nameSearch: this.nameSearch,
      });
    },
    async submitNewGradeType() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/grade-type", this.newGradeType);
        console.log("submitted?", response);
        this.$Notifications(
          this.$t('add success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
        this.dialog = false;
      } catch (err) {
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
  created() {
    if (this.returnAbility("grade-type:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style></style>
